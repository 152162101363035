/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React from 'react';
import './index.css';
import App from './App';
import AuthProvider from './components/auth/AuthContext';
import OneOffAuthProvider from './components/oneOffAuth/OneOffAuthContext';
import Keycloak from 'keycloak-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { addIdTokenToRequests } from './containers/Errors/HttpInterceptor';
import { handleTokenExpiredEvent } from './components/auth/TokenUtils';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { getConfig } from './Config';
import { createRoot } from 'react-dom/client';

const setUpApp = async () => {
  const keycloak = Keycloak('/config/keycloak.json');
  const matomoSiteId = getConfig().matomoSiteId;
  const matomoUrl = getConfig().matomoUrl;
  const authenticated = localStorage.getItem('authenticated') === 'true';

  keycloak.onAuthSuccess = () => {
    localStorage.setItem('idToken', keycloak.idToken);
    localStorage.setItem('refreshToken', keycloak.refreshToken);
    localStorage.setItem('authenticated', 'true');
  };
  
  keycloak.onAuthLogout = () => {
    localStorage.setItem('authenticated', 'false');
  };

  const instance = createInstance(
    {
      urlBase: matomoUrl,
      siteId: matomoSiteId,
      trackerUrl: `${matomoUrl}/matomo.php`,
      srcUrl: `${matomoUrl}/matomo.js`,
      disabled: false,
      heartBeat: {
        active: true,
        seconds: 10,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    },
    [matomoSiteId, matomoUrl]
  );

  const renderApp = async () => {
    if (!authenticated) {
      await keycloak.init({
        promiseType: 'native',
        onLoad: 'login-required',
        checkLoginIframe: false
      });
    } else {
      const idToken = localStorage.getItem('idToken');
      const refreshToken = localStorage.getItem('refreshToken');
      await keycloak.init({
        promiseType: 'native',
        onLoad: 'check-sso',
        checkLoginIframe: false,
        idToken,
        refreshToken
      });
    }
    const root = createRoot(document.getElementById('root'));
    root.render(
      <MatomoProvider value={instance}>
        <AuthProvider keycloak={keycloak}>
          <OneOffAuthProvider>
            <Router>
              <App keycloak={keycloak} />
            </Router>
          </OneOffAuthProvider>
        </AuthProvider>
      </MatomoProvider>
    );
  };

  handleTokenExpiredEvent(keycloak);
  addIdTokenToRequests(keycloak);
  await renderApp();
};

await setUpApp();
