export const handleTokenExpiredEvent = (keycloak) => {
  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(300)
      .then()
      .catch(() => {
        // If updating the token fails invalidate the session to force login when user performs an action
        keycloak.clearToken();
      });
  };
};
